// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap';
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import intlTelInput from 'intl-tel-input';
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import select2 from 'select2'

import bsCustomFileInput from 'bs-custom-file-input'
import Sortable from 'sortablejs';

global.Sortable = window.Sortable = Sortable;
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.bsCustomFileInput = bsCustomFileInput
window.intlTelInput = intlTelInput

window.ChartDataLabels = ChartDataLabels
window.Chart = Chart
window.registerables = registerables


import { Jodit } from 'jodit'; window.Jodit = Jodit

Chart.register(...registerables);

require("packs/devise")
require("packs/currency_field")
require("packs/specializations")
require("packs/help")

var L = require('leaflet')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Rails = Rails

window.joditButtons =  ["bold", "italic", "underline", "ul", "ol", "indent", "outdent", "align", "image", "link", "eraser"]

window.joditConfig = {
    "placeholder": '',
    "askBeforePasteHTML": false,
    "askBeforePasteFromWord": false,
    "defaultActionOnPaste": 'insert_only_text',
    "processPasteHTML": false,
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "showCharsCounter": false,
    "showWordsCounter": false,
    "showXPathInStatusbar": false,
    "maxHeight": 400,
    "buttonsXS": window.joditButtons,
    "buttonsSM": window.joditButtons,
    "buttonsMD": window.joditButtons,
    "buttons": window.joditButtons
}

window.joditInit = function ($element) {
  var $this = $element
  var editor = new Jodit($this[0], window.joditConfig);
    
  editor.events.on('change', function (text) {
    $this.parent('.jodit_wrapper').find('input').val(editor.value)
  });
}

$(document).on('turbolinks:load', function() {

  $('.jodit').each(function () {
    joditInit($(this))
  });
  

  $('[data-s2="true"]').each(function() {
    $(this).select2()
  })

  

  $('[data-s2="language"]').select2({
    templateResult: (state) => {
      if (!state.id) {
        return state.text;
      }

      var $state = $(
        '<span><span class="language-flag language-flag-' + $(state.element).data('abbr') +' mr-2"></span>' + state.text +'</span>'
      )

      return $state;
    },

    templateSelection: (state) => {
      if (!state.id) {
        return state.text;
      }

      var $state = $(
        '<span><span class="language-flag language-flag-' + $(state.element).data('abbr') +' mr-2"></span>' + state.text +'</span>'
      )

      return $state;
    }
  });

  $('[data-s2="currency"]').select2({
    templateResult: (state) => {
      if (!state.id) {
        return state.text;
      }

      var $state = $(
        '<span><span class="currency-flag currency-flag-' + state.text +' mr-2"></span>' + state.text +'</span>'
      )

      return $state;
    },

    templateSelection: (state) => {
      if (!state.id) {
        return state.text;
      }

      var $state = $(
        '<span><span class="currency-flag currency-flag-' + state.text +' mr-2"></span>' + state.text +'</span>'
      )

      return $state;
    }
  });

  if ($('#map').length > 0) {
    map_init()
  }

  bsCustomFileInput.init()
  $('.wrapper-tooltip').tooltip()
    
  $('.format_number').on("keyup", function() {
    format_number($(this))
  });

  $('.format_number').each(function() {
    format_number($(this))
  });
})
  
$(document).on('click', '.once', function(e) {
  $(this).addClass('disabled')
})

$(document).on('change', '#company', function() {
  var parent_id = $(this).val()

  $.get(`/companies/${parent_id}/departments/render_departments`)
})

$(document).on('click', '.clickable', function(e) {
  if ($(e.target).is('a, svg, path')) return
  if ($(this).data("href").length < 1) return

  Turbolinks.visit($(this).data("href"))
})

$(document).on('click', '.clickable-modal', function(e) {
  var target = $(e.target)
  
  if (target.is('a, svg, path')) {
    return
  } else if (target.is('td, p')) {
    target = target.parents('tr')
  }

  $.get(target.data("href"))
})

// map
function map_init() {
  var map = L.map('map')
  var coordinates;
	
	L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
	}).addTo(map)
	
  var icon = new L.Icon({
    iconUrl: $('#map').data('icon'),
    iconAnchor: new L.Point(9, 32)
  })
 
  // get address from data
  var address = {
    street: $('#map').data('street'),
    city: $('#map').data('city'),
    zip: $('#map').data('zip'),
  }

  // search address works better with nominatim if it doesnt include building name, housenumber and zipcode
  var search_address = OSMParser.street(address.street, address.city) + ', ' +  OSMParser.city(address.city) + ', ' + address.zip

  $.get('https://nominatim.openstreetmap.org/search.php?q=' + search_address + '&format=json', function(nominatim) {
    // if some results found
    if (nominatim[0] !== undefined) {
      coordinates = [nominatim[0].lat, nominatim[0].lon]
      L.marker(coordinates, { icon: icon })
       .addTo(map)
       .bindPopup($('#address').text())
      
       map.setView(coordinates, 15)

    // else if address was not found, show only city
    } else {
      $.get('https://nominatim.openstreetmap.org/search.php?q=' + OSMParser.city(city) + '&format=json', function(nominatim) {
        if (nominatim[0] !== undefined) { 
          map.setView([nominatim[0].lat, nominatim[0].lon], 15) 
        }
      })
    }

  })
}		

var OSMParser = {
  street: function(street, city) {
    city = city || ''

    street = street.replace(/[A-Z]\./g, '') // removes abbreviations, should be here, eg.: tř. T. Bati 627 => tř. Bati 627
    
    street = street.toLowerCase()
    street = street.replace(', čp', ' čp')
    street = street.replace(/čp.*$/g, '')
    street = street.replace(/^[^-]*-/g, '') // removes from beginning to "-" (if address contains building name)
    street = street.replace(/^[^,]*,/g, '') // removes from beginning to "," (if address contains building name)  
    street = street.replace(/ (?=[^ ]*$).*\//g, ' ') // removes land registry number, eg.: 1164/3 => 3 

    street = street.replace(city.toLowerCase(), '') // remove city from street name

    // Romania 
    street = street.replace('nr.', '')
    street = street.replace('ap.', '')

    return street
  },
  city: function(city) {
    return city.replace(/[0-9]/g, '')
    // -.*$
  }
}

// this method reload a pages and remember scroll position
function reloadWithTurbolinks() {
  Turbolinks.clearCache()
  window.scrollPosition = [window.scrollX, window.scrollY]
  Turbolinks.visit(window.location.toString(), { action: 'replace' })
}

window.reloadWithTurbolinks = reloadWithTurbolinks

document.addEventListener('turbolinks:load', function () {
  if (window.scrollPosition) {
    window.scrollTo.apply(window, window.scrollPosition)
    window.scrollPosition = null
  }
})

// This method save and reload the page without flickering or scroll change
function submitWithAjax() {
  var form = $('form');
  var url = form.attr('action');
   
  $.ajax({
    type: "post",
    url: url,
    data: form.serialize(),
    complete: function(data)
    {
      window.reloadWithTurbolinks()
    }
  });
}
window.submitWithAjax = submitWithAjax

// This method just saves the form in the background
function saveWithAjax(callback) {
  var form = $('form');
  var url = form.attr('action');
   
  $.ajax({
    type: "post",
    url: url,
    data: form.serialize(),
    complete: function(data)
    {
      if (typeof callback !== 'undefined') {
        callback()
      }
    }
  })
}
window.saveWithAjax = saveWithAjax

// This method just saves the form in the background
function saveFilesWithAjax(callback) {
  var form = $('form');
  var url = form.attr('action');
  var form_data = new FormData(form.get(0))
   
  $.ajax({
    type: "post",
    url: url,
    data: form_data,
    contentType: false,
    processData: false,
    complete: function(data)
    {
      if (typeof callback !== 'undefined') {
        callback()
      }
    }
  })
}
window.saveFilesWithAjax = saveFilesWithAjax

function format_number($this) {
  var value = $this.val()

  if (value.match(/^[0-9 ,.]+$/) == null) return

  $this.value = $this.val($this.val().replace(/ /g,''))
  var number = $this.val();

  $this.val(number.replace(/\B(?=(\d{3})+(?!\d))/g, " "))
}

window.format_number = format_number

// spinner 
$(document).on('click', '#send-email [type="submit"]', function() {
  $('.rotating.hidden').removeClass('hidden')
})

// fakedrop remove

$(document).on('click', '[href="#fakedrop_remove"]', function(e) {
  e.preventDefault()

  let attr = $(this).data('attr')
  let object = $(this).data('object')

  $(`[name="${object}[remove_${attr}]"][type="hidden"]`).val(1)

  saveFilesWithAjax(reloadWithTurbolinks)
})