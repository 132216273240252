$(document).on('click', '.help-link', function(e) {
  e.preventDefault()
  history.pushState({turbolinks: true, url: e.target.href}, '', e.target.href);

  $('.help-link').removeClass('active')
  $(this).addClass('active')

  var href = $(this).attr('href')
  setHelpTab(href)
})

$(document).on('turbolinks:load', function() {
  if (!location.href.includes('/help')) return

  if (location.hash === '') {
    $('[href="#about"]').addClass('active')
    setHelpTab("#about")
  } else {
    $('[href="' + location.hash + '"]').addClass('active')
    setHelpTab(location.hash)
  }

})

function setHelpTab(href) {
  $('.help-tab').hide()
  $(href).show()
}
