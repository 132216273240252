const currencyField = {
  init() {
    $('.currency_field').on('input', function() {
      var value = $(this).val().replaceAll(' ', '')
      $(this).next('[type="hidden"]').val(value)
    })
  }
}

window.currencyField = currencyField

$(document).on('turbolinks:load', function() {
  currencyField.init()
})
